import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CampaignsService from '../../services/CampaignsService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faEye, faPlay } from '@fortawesome/free-solid-svg-icons';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const validUser = (sentUsers) => {
  if (!sentUsers) return '';
  return `${sentUsers} users`;
};

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    campaignName: '',
    sentDate: '',
  });
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [newCampaign, setNewCampaign] = useState({
    campaignName: '',
    title1: '',
    city1: '',
    image1: null,
    title2: '',
    city2: '',
    image2: null,
    title3: '',
    city3: '',
    image3: null,
    title4: '',
    city4: '',
    image4: null,
    title5: '',
    city5: '',
    image5: null,
    title6: '',
    city6: '',
    image6: null
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState('');
  const [selectedLocale, setSelectedLocale] = useState('es');
  const [sendSuccessMessage, setSendSuccessMessage] = useState('');
  const [sendErrorMessage, setSendErrorMessage] = useState('');
  const [campaignId, setCampaignId] = useState(null);
  const [formError, setFormError] = useState('');
  const [showStartModal, setShowStartModal] = useState(false);
  const [startSuccessMessage, setStartSuccessMessage] = useState('');
  const [startErrorMessage, setStartErrorMessage] = useState('');

  const fetchData = async (page) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const data = await CampaignsService.getCampaignData(authToken, page, pageSize, filters);
        setCampaigns(data.content);
        setTotalPages(data.totalPages);
      } else {
        setError('No auth token found');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  const handleEnabledChange = async (campaignId, newEnabled) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        await CampaignsService.updateCampaignEnabled(authToken, campaignId, newEnabled);
        fetchData(currentPage);
      } else {
        setError('No auth token found');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedCampaigns = [...campaigns].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCampaign((prevCampaign) => ({
      ...prevCampaign,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (file && validateFileExtension(file)) {
        setNewCampaign((prevCampaign) => ({
          ...prevCampaign,
          [name]: file,
        }));
      } else {
        setError('Invalid file type. Only .jpg, .jpeg, and .png files are allowed.');
      }
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ['.jpg', '.jpeg', '.png'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return allowedExtensions.includes(`.${fileExtension}`);
  };

  const handleCreateCampaign = async () => {

    setFormError(''); // Clear any previous form errors

    if (!newCampaign.campaignName.trim()) {
      setFormError('Campaign Name is required');
      return;
    }

    setIsSubmitting(true);  // Activa el spinner
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setError('No auth token found');
        return;
      }

      const formData = new FormData();
      formData.append('campaignName', newCampaign.campaignName);
      if (newCampaign.image1) formData.append('title1', newCampaign.title1);
      if (newCampaign.image1) formData.append('city1', newCampaign.city1);
      if (newCampaign.image1) formData.append('image1', newCampaign.image1);
      if (newCampaign.image2) formData.append('title2', newCampaign.title2);
      if (newCampaign.image2) formData.append('city2', newCampaign.city2);
      if (newCampaign.image2) formData.append('image2', newCampaign.image2);
      if (newCampaign.image3) formData.append('title3', newCampaign.title3);
      if (newCampaign.image3) formData.append('city3', newCampaign.city3);
      if (newCampaign.image3) formData.append('image3', newCampaign.image3);
      if (newCampaign.image4) formData.append('title4', newCampaign.title4);
      if (newCampaign.image4) formData.append('city4', newCampaign.city4);
      if (newCampaign.image4) formData.append('image4', newCampaign.image4);
      if (newCampaign.image5) formData.append('title5', newCampaign.title5);
      if (newCampaign.image5) formData.append('city5', newCampaign.city5);
      if (newCampaign.image5) formData.append('image5', newCampaign.image5);
      if (newCampaign.image6) formData.append('title6', newCampaign.title6);
      if (newCampaign.image6) formData.append('city6', newCampaign.city6);
      if (newCampaign.image6) formData.append('image6', newCampaign.image6);

      await CampaignsService.createCampaign(authToken, formData);
      fetchData(currentPage);
      setShowCreateForm(false);
      setSuccessMessage('Campaign created successfully!');

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);  // Desactiva el spinner
    }
  };

  const handleOpenStartModal = (id) => {
    setCampaignId(id);
    setShowStartModal(true);
  };

  const handleStartCampaign = async () => {
    setIsSubmitting(true); // Activa el spinner
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setError('No auth token found');
        return;
      }

      if (campaignId) {
        await CampaignsService.startCampaign(authToken, campaignId);

        setStartSuccessMessage('Campaign started successfully!');
        setStartErrorMessage('');
        fetchData(currentPage);

        setTimeout(() => {
          setStartSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      setStartSuccessMessage('');
      setStartErrorMessage('An unexpected error occurred. Please try again later.');

      setTimeout(() => {
        setStartErrorMessage('');
      }, 3000);
    } finally {
      setIsSubmitting(false); // Desactiva el spinner
    }
  };


  const handleCloseStartModal = () => {
    setShowStartModal(false);
    setStartSuccessMessage('');
    setStartErrorMessage('');
  };


  const handleViewCampaign = (id) => {
    setCampaignId(id);
    setShowSendModal(true);
  };

  const handleviewCampaignByIdAndLocale = async (email, selectedLocale) => {
    setIsSubmitting(true);  // Activa el spinner
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setError('No auth token found');
        return;
      }

      if (authToken && campaignId) {
        await CampaignsService.viewCampaignByIdAndLocale(authToken, campaignId, selectedLocale, email);

        setSendSuccessMessage('Email sent successfully!');
        setSendErrorMessage('');

        setTimeout(() => {
          setSendSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      setSendSuccessMessage('');
      setSendErrorMessage('Please check the value you entered and try again');

      setTimeout(() => {
        setSendErrorMessage('');
      }, 3000);
    } finally {
      setIsSubmitting(false);  // Desactiva el spinner
    }
  };

  const handleCloseSendModal = () => {
    setShowSendModal(false);
    setSendSuccessMessage('');
    setSendErrorMessage('');
    setEmail('');
    setSelectedLocale('es');
  };


  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <section className="body">
      <header className="page-header">
        <h2>Campaigns</h2>
        <div className="right-wrapper pull-right">
          <ol className="breadcrumbs">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-envelope"></i>
              </Link>
            </li>
            <li><span>Campaigns</span></li>
          </ol>
          <a className="sidebar-right-toggle" data-open="sidebar-right">
            <i className="fa fa-chevron-left"></i>
          </a>
        </div>
      </header>

      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Campaign List</h2>
          <div className="panel-body">
            <div className="filter-form row">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  name="campaignName"
                  placeholder="Campaign Name"
                  value={filters.campaignName}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <input
                  type="date"
                  className="form-control"
                  name="sentDate"
                  placeholder="Sent Date"
                  value={filters.sentDate}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary" onClick={() => setShowCreateForm(true)}>Create Campaign</button>
              </div>
            </div>
          </div>
        </header>
        <div className="panel-body">
          {successMessage && (
            <div className="alert alert-success" role="alert">
              {successMessage}
            </div>
          )}
          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-none">
              <thead>
                <tr>
                  <th onClick={() => handleSort('enabled')} style={{ whiteSpace: 'nowrap' }}>
                    Enabled {getSortIcon('enabled')}
                  </th>
                  <th onClick={() => handleSort('campaignName')} style={{ whiteSpace: 'nowrap' }}>
                    Campaign Name {getSortIcon('campaignName')}
                  </th>
                  <th onClick={() => handleSort('sentDate')} style={{ whiteSpace: 'nowrap' }}>
                    Sent Date{getSortIcon('sentDate')}
                  </th>
                  <th onClick={() => handleSort('totalSent')} style={{ whiteSpace: 'nowrap' }}>
                    Total Sent {getSortIcon('totalSent')}
                  </th>
                  <th>View</th>
                  <th>Start</th>
                </tr>
              </thead>
              <tbody>
                {sortedCampaigns.map((campaign) => (
                  <tr key={campaign.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={campaign.enabled}
                        onChange={(e) => handleEnabledChange(campaign.id, e.target.checked)}
                      />
                    </td>
                    <td>{campaign.campaignName}</td>
                    <td>{formatDate(campaign.sentDate)}</td>
                    <td>{validUser(campaign.totalSent)}</td>
                    <td>
                      <button className="btn btn-primary" onClick={() => handleViewCampaign(campaign.id)}>
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-primary" onClick={() => handleOpenStartModal(campaign.id)}>
                        <FontAwesomeIcon icon={faPlay} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button className="btn btn-pagination" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
              Previous
            </button>
            <span>Page {currentPage + 1} of {totalPages}</span>
            <button className="btn btn-pagination" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages - 1}>
              Next
            </button>
          </div>
        </div>
      </section>

      {/* Modal for creating a campaign */}
      {showCreateForm && (
        <div className="modal-overlay" onClick={() => setShowCreateForm(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Create New Campaign</h3>
              <button className="close-button" onClick={() => setShowCreateForm(false)}>X</button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                  <label>Campaign Name:</label>
                  <input
                    type="text"
                    name="campaignName"
                    className="form-control"
                    value={newCampaign.campaignName}
                    onChange={handleInputChange}
                    placeholder="Campaign Name"
                    required
                  />
                  <span className="font-color-red">{formError}</span> {/* Display form error */}
                </div>

                {/* Separator */}
                <hr />

                <div className="form-group">
                  <h4>Information</h4>
                </div>

                {/* Repeat the form-group for each title and city */}
                {[1, 2, 3, 4, 5, 6].map((num) => (
                  <React.Fragment key={num}>
                    <div className="form-group">
                      <label>Title {num}:</label>
                      <input
                        type="text"
                        className="form-control"
                        name={`title${num}`}
                        value={newCampaign[`title${num}`]}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>City {num}:</label>
                      <input
                        type="text"
                        name={`city${num}`}
                        className="form-control"
                        value={newCampaign[`city${num}`]}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="form-group">
                      <label>Image {num}:</label>
                      <input
                        type="file"
                        name={`image${num}`}
                        className="form-control"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                    </div>

                    {/* Separator */}
                    {num < 6 && <hr />}
                  </React.Fragment>
                ))}

                <hr />
                <div className="form-group text-right">
                  <button type="button" className="btn btn-primary" onClick={handleCreateCampaign} disabled={isSubmitting}>
                    {isSubmitting ? <div className="spinner"></div> : 'Create'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal for sending a campaign */}
      {showSendModal && (
        <div className="modal-overlay" onClick={handleCloseSendModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Send Campaign</h3>
              <button className="close-button" onClick={handleCloseSendModal}>X</button>
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => {
                e.preventDefault();  // Previene el comportamiento por defecto
                if (email) {         // Comprueba si el email tiene valor
                  handleviewCampaignByIdAndLocale(email, selectedLocale);
                } else {
                  setSendErrorMessage('Please provide a valid email address.');
                }
              }}>
                <div className="form-group">
                  <label>Locale:</label>
                  <select
                    name="locale"
                    className="form-control"
                    value={selectedLocale}
                    onChange={(e) => setSelectedLocale(e.target.value)}
                  >
                    <option value="es">Spanish</option>
                    <option value="en">English</option>
                    <option value="fr">French</option>
                    <option value="de">German</option>
                    <option value="it">Italian</option>
                    <option value="pt">Portuguese</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group text-right">
                  <button
                    type="submit" // Cambiado a tipo submit
                    className="btn btn-primary"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <div className="spinner"></div> : 'Send'}
                  </button>
                </div>
                {sendSuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {sendSuccessMessage}
                  </div>
                )}
                {sendErrorMessage && (
                  <div className="alert alert-danger" role="alert">
                    {sendErrorMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}

      {/* Modal for starting a campaign */}
      {showStartModal && (
        <div className="modal-overlay" onClick={handleCloseStartModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Start Campaign</h3>
              <button className="close-button" onClick={handleCloseStartModal}>X</button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to start this campaign for the enrolled users?</p>
              <div className="form-group text-right">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseStartModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleStartCampaign}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <div className="spinner"></div> : 'Start'}
                </button>
              </div>
              {startSuccessMessage && (
                <div className="alert alert-success" role="alert">
                  {startSuccessMessage}
                </div>
              )}
              {startErrorMessage && (
                <div className="alert alert-danger" role="alert">
                  {startErrorMessage}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

    </section>
  );
};

export default Campaigns;
