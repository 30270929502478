import React from 'react';
import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Header from './components/header/Header';
import SidebarLeft from './components/sidebarLeft/SidebarLeft';
import PageHeader from './components/pageHeader/PageHeader';
import DashboardSummaries from './components/dashboard/DashboardSummaries';
import Contacts from './components/contacts/Contacts';
import Newsletter from './components/newsletter/Newsletter';
import Campaigns from './components/campaigns/Campaigns';
import PrivateRoute from './components/privateRoute/PrivateRoute'; 

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard/*" element={<PrivateRoute element={<DashboardLayout />} />} />
      </Routes>
    </Router>
  );
}

const DashboardLayout = () => (
  <section className="body">
    <Header />
    <div className="inner-wrapper">
      <SidebarLeft />
      <section role="main" className="content-body">
        <PageHeader />
        <Routes>
          <Route path="summaries" element={<DashboardSummaries />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="newsletter" element={<Newsletter />} />
          <Route path="campaigns" element={<Campaigns />} />
          <Route path="*" element={<DashboardSummaries />} /> 
        </Routes>
      </section>
    </div>
  </section>
);

export default App;
