const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class ContactService {
    
    static async getContactData(token, page, size, filters) {
        const queryParams = new URLSearchParams({
            page,
            size,
            name: filters.name || '',
            country: filters.country || '',
            pageId: filters.pageId || '',
            status: filters.status || '',
            date: filters.date || ''
        }).toString();

        const response = await fetch(`${apiUrl}/contact?${queryParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }

    static async updateContactStatus(token, contactId, newStatus) {
        const response = await fetch(`${apiUrl}/contact/${contactId}/status`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: newStatus }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }

    static async updateContactNotes(token, contactId, newNote) {
        const response = await fetch(`${apiUrl}/contact/${contactId}/notes`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ notes: newNote }), 
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }

    static async sendContactRequest(formData) {
        const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
        const response = await fetch(`${apiUrl}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

}

export default ContactService;
