const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class CampaignsService {

    static async getCampaignData(token, page, size, filters) {
        const queryParams = new URLSearchParams({
            page,
            size,
            campaignName: filters.campaignName || '',
            sentDate: filters.sentDate || ''
        }).toString();

        const response = await fetch(`${apiUrl}/campaigns?${queryParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }

    static async updateCampaignEnabled(token, campaignId, enabled) {
        const response = await fetch(`${apiUrl}/campaigns/${campaignId}/enabled`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ enabled }),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }

    static async createCampaign(token, formData) {
        const response = await fetch(`${apiUrl}/campaigns`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': `Bearer ${token}`
            },
            //body: JSON.stringify(campaignData),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    static async viewCampaignByIdAndLocale(token, campaignId, locale, email) {
        const response = await fetch(`${apiUrl}/campaigns/view/${email}/${campaignId}/${locale}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    static async startCampaign(token, campaignId) {
        const response = await fetch(`${apiUrl}/campaigns/start/${campaignId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        return;
    }
}

export default CampaignsService;
