import React from 'react';
import { Navigate } from 'react-router-dom';

// Función para verificar si el usuario está autenticado
const isAuthenticated = () => {
  return !!localStorage.getItem('authToken'); // Devuelve true si el token existe
};

const PrivateRoute = ({ element }) => {
  // Si el usuario no está autenticado, redirigir al login
  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  // Si el usuario está autenticado, mostrar el componente solicitado
  return element;
};

export default PrivateRoute;
