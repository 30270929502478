import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/LoginService';

const Header = ({ data }) => {
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [password, setpassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [username, setDisplayName] = useState('');

  useEffect(() => {
    const storedUsername = localStorage.getItem('displayName');
    if (storedUsername) {
      setDisplayName(storedUsername);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const handleChangePassword = async () => {
    // Validación de contraseñas
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match!');
      return;
    }

    setIsSubmitting(true);
    setErrorMessage('');

    
    try {
      const requestBody = { password };
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        await LoginService.changePassword(requestBody);

        setSuccessMessage('Password changed successfully!');

        setTimeout(() => {
          setSuccessMessage('');
          setShowChangePasswordModal(false); 
        }, 3000);
      }
    } catch (error) {
      setErrorMessage('Error changing password, please try again.');

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <header className="header">
        <div className="logo-container">
          <div className="logo">
            <div className="font-logo custom-primary-color">
              <img src="img/san-partners-black.svg" alt="San & Partners Logo" className="logo-img" />
              San&<span>Partners</span>
            </div>
          </div>
					<div className="visible-xs toggle-sidebar-left" data-toggle-class="sidebar-left-opened" data-target="html" data-fire-event="sidebar-left-opened">
						<i class="fa fa-bars" aria-label="Toggle sidebar"></i>
					</div>
        </div>

        <div className="header-right">
          <span className="separator"></span>

          <div id="userbox" className="userbox">
            <a href="#" data-toggle="dropdown">
              <div className="profile-info">
                <span className="name">{username}</span>
                <span className="role">administrator</span>
              </div>
              <i className="fa custom-caret"></i>
            </a>

            <div className="dropdown-menu">
              <ul className="list-unstyled">
                <li className="divider"></li>
                <li>
                  <a role="menuitem" tabIndex="-1" href="#" onClick={() => setShowChangePasswordModal(true)}>
                    <i className="fa fa-user"></i> Change password
                  </a>
                </li>
                <li>
                  <a role="menuitem" tabIndex="-1" href="/" onClick={(e) => { e.preventDefault(); handleLogout(); }}>
                    <i className="fa fa-power-off"></i> Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>

      {/* Modal for changing password */}
      {showChangePasswordModal && (
        <div className="modal-overlay" onClick={() => setShowChangePasswordModal(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <h3>Change Password</h3>
              <button className="close-button" onClick={() => setShowChangePasswordModal(false)}>X</button>
            </div>
            <div className="modal-body">
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
              {successMessage && <div className="alert alert-success">{successMessage}</div>}
              <div className="form-group">
                <label htmlFor="password">New Password:</label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => setpassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmPassword">Confirm New Password:</label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowChangePasswordModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleChangePassword}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Changing...' : 'Change Password'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
