import React from 'react';
import { Link } from 'react-router-dom';

const PageHeader = () => {
  
  return (
    <header className="page-header">
      <h2>Dashboard</h2>
      
      <div className="right-wrapper pull-right">
        <ol className="breadcrumbs">
          <li>
            <a href="index.html">
              <i className="fa fa-home"></i>
            </a>
          </li>
          <li><span>Dashboard</span></li>
        </ol>

        <Link className="sidebar-right-toggle" data-open="sidebar-right">
          <i className="fa fa-chevron-left"></i>
        </Link>
      </div>
    </header>
  );
};

export default PageHeader;
